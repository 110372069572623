/* CSS - Cascading Style Sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=13o0u0kllllaFw0g0qFqFg0w0aF */

/* Feel free to copy&paste color codes to your application */


/* As hex codes */

.color-primary-0 { color: #265B6A }	/* Main Primary color */
.color-primary-1 { color: #6B939F }
.color-primary-2 { color: #447684 }
.color-primary-3 { color: #0F414F }
.color-primary-4 { color: #022A35 }
/* Generated by Paletton.com © 2002-2014 */
/* http://paletton.com */
.header {

}
