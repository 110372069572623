body{
  margin: auto 0;
  background-color: #12232F;
}
.App {
  margin-top: 0;

}
#Particle-Canvas {
  position: fixed !important;
  top: 0;
  left: 0;
  /*background-image: url('background.jpg');*/
  /*background-size: cover;*/
  width: 100%;
  height: 100%;
  /*z-index: -1 !important;*/
  /*background-color: rgba( 0, 0, 0, .2 ) !important;*/
}
